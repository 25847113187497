<template>
    <div>
        <el-dialog append-to-body title="选择门店" :visible.sync="dialogVisible" width="800px" :before-close="handleClose">
            <div class="dialog_bos flex-column-center">
                <div class="h_head_store">
                    <div class="head_title">连锁门店结构</div>
                    <div class="head_tree tree_info">
                        <el-tree :expand-on-click-node="false" :check-strictly="true" @node-click="treeNodeClick" @check-change="handleCheckChange" ref="tree" class="filter-tree" :data="listShop" :props="defaultProps" node-key="shop_id" default-expand-all show-checkbox>
                        </el-tree>
                    </div>
                </div>
                <div class="checked_all flex-row-center">
                    <el-button type="primary" @click="onAll">全 选</el-button>
                    <el-button type="primary" @click="onNall">取消全选</el-button>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 认</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            listShop: [],
            dialogVisible: false,
            defaultProps: {
                children: 'children',
                label: 'all_name',
            },
            checkedList: [],
        }
    },
    mounted() { },
    methods: {
        open(list) {
            this.dialogVisible = true
            this.listShop = [
                {
                    all_name: '001-中兄次之乡村振兴',
                    shop_id: 1,
                    children: [
                        {
                            all_name: '002-中兄次之乡村振兴',
                            shop_id: 2,
                        },
                        {
                            all_name: '003-中兄次之乡村振兴',
                            shop_id: 3,
                        }
                    ]
                }
            ]
            if (list && list.length > 0) {
                let id_list = []
                list.forEach(item => {
                    id_list.push(item.shop_id)
                })
                this.$nextTick(() => {
                    this.$refs.tree.setCheckedKeys(id_list)
                });

            }
        },
        handleCheckChange(data, checked) {
            if (checked) {
                this.checkedList.push(data)
            } else {
                this.checkedList.forEach((item, index) => {
                    if (item.shop_id == data.shop_id) {
                        this.checkedList.splice(index, 1)
                    }
                })
            }
        },
        treeNodeClick(data, checked) {
            checked.checked = !checked.checked
            this.handleCheckChange(data, !checked.checked)
        },
        handleCheck(node, list) {
            this.$refs.tree.setCheckedKeys([node.shop_id]);
            this.checkedList = [node]
        },
        //全选
        onAll() {
            let list = []
            this.checkedList = []
            this.listShop.forEach(item1 => {
                list.push(item1.shop_id)
                this.checkedList.push(item1)
                if (item1.children) {
                    item1.children.forEach(item2 => {
                        list.push(item2.shop_id)
                        this.checkedList.push(item2)
                        if (item2.children) {
                            item2.children.forEach(item3 => {
                                list.push(item3.shop_id)
                                this.checkedList.push(item3)
                            });
                        }
                    });
                }
            });
            this.$refs.tree.setCheckedKeys(list)
        },
        //取消全选
        onNall() {
            this.$refs.tree.setCheckedKeys([])
            this.checkedList = []
        },
        handleClose() {
            this.dialogVisible = false
        },
        submit() {
            this.$emit('change', this.checkedList)
            this.handleClose()
        },
    },

}
</script>
<style lang="less" scoped>
/deep/.is-checked {
    .el-tree-node__content {
        background-color: rgba(64, 158, 255, 0.1);
        color: #409eff;
    }
    .el-tree-node__children {
        .el-tree-node__content {
            background-color: #ffffff !important;
            color: #606266;
        }
    }
}
.dialog_bos {
    width: 100%;
    .h_head_store {
        width: 700px;
        .head_tree {
            height: 500px;
            overflow-y: scroll;
        }
    }
    .checked_all {
        width: 100%;
        padding: 20px 30px;
    }
}

.h_head_store {
    width: 350px;
    border: 1px #e2e4ea solid;
    border-radius: 10px;
    overflow: hidden;

    .head_title {
        height: 50px;
        width: 100%;
        background-color: #4772ff;
        color: #ffffff;
        line-height: 50px;
        text-align: center;
        font-size: 16px;
    }

    .head_tree {
        padding: 10px;
    }
}

/deep/.el-dialog__body {
    padding-bottom: 0px;
}

/deep/.tree_info {
    .el-tree-node__content {
        height: 36px;
        padding-left: 0px !important;
    }

    //这里是加虚线的
    .el-tree > .el-tree-node:after {
        border-top: none;
    }
    .el-tree-node {
        position: relative;
        padding-left: 16px;
    }
    //节点有间隙，隐藏掉展开按钮就好了,如果觉得空隙没事可以删掉
    .el-tree-node__expand-icon.is-leaf {
        display: none;
    }
    .el-tree-node__children {
        padding-left: 16px;
    }

    .el-tree-node :last-child:before {
        // height: 38px;
        height: 44px;
    }

    .el-tree > .el-tree-node:before {
        border-left: none;
    }

    .el-tree > .el-tree-node:after {
        border-top: none;
    }

    .el-tree-node:before {
        content: "";
        left: -4px;
        position: absolute;
        right: auto;
        border-width: 1px;
    }

    .el-tree-node:after {
        content: "";
        left: -4px;
        position: absolute;
        right: auto;
        border-width: 1px;
    }

    .el-tree-node:before {
        border-left: 1px dashed #606266;
        bottom: 0px;
        height: 100%;
        top: -26px;
        width: 1px;
    }

    .el-tree-node:after {
        border-top: 1px dashed #606266;
        height: 20px;
        // top: 12px;
        top: 18px;
        width: 24px;
    }
}
</style>
