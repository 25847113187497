<template>
    <div class="">
        <el-dialog :close-on-click-modal="false" append-to-body title="选择门店" :visible.sync="dialogVisible" width="1000px" :before-close="handleClose">
            <div>
                <div class="flex-row-start">
                    <el-input v-model="httpobj.var" placeholder="请输入连锁代码/编号/代码-编号" style="width:300px">
                        <el-button @click="getData(1)" slot="append" icon="el-icon-search"></el-button>
                    </el-input>
                </div>
                <el-table @row-dblclick="rowdblclick" :row-class-name="tableRowClassName" @row-click="rowclick" size="medium" :data="tableData" style="width: 100%;margin-top:20px" :header-cell-style="{color: '#68718c', backgroundColor: '#e4e8f0'}">
                    <el-table-column prop="shop_code" align="center" label="连锁代码" min-width="100" />
                    <el-table-column prop="shop_number" align="center" label="门店编号" min-width="100" />
                    <el-table-column prop="name" align="center" label="门店全称" min-width="160" />
                    <el-table-column prop="abbreviation" align="center" label="门店简称" min-width="160" />
                </el-table>
                <div class="flex-row-between mt10">
                    <div></div>
                    <el-pagination @size-change="getData" @current-change="getData" :page-size="httpobj.limit" :current-page.sync="httpobj.page" layout="prev, pager, next, jumper" :total="total">
                    </el-pagination>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 认</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            dialogVisible: false,
            total: 0,
            tableData: [],
            httpobj: {
                var: '', name: '', pid: '', status: '1', limit: 10, page: 1,
            },
            checked_obj: {},
            checked_list: [],
            type: '',
        }
    },
    mounted() {


    },
    methods: {
        open() {
            this.getData(1)
            this.dialogVisible = true
        },
        //列表数据
        getData(page) {
            this.httpobj.page = page
            this.$http.api("api/store/listStore", this.httpobj).then((res) => {
                res.data.data.forEach(item => {
                    this.$set(item, 'checked', false)
                })
                this.tableData = res.data.data
                this.total = res.data.total
            })
        },
        handleClose() {
            this.dialogVisible = false
        },
        rowclick(row) {
            row.checked = !row.checked
        },
        submit() {
            this.checked_list = []
            this.tableData.forEach(item => {
                if (item.checked) {
                    this.checked_list.push(item)
                }
            })
            this.$emit('change', this.checked_list)
            this.dialogVisible = false
        },
        rowdblclick(row) {
            this.$emit('change', [row])
            this.dialogVisible = false
        },
        tableRowClassName({ row, rowIndex }) {
            if (row.checked) {
                return 'b4772FF'
            }
            return '';
        },
    },

}
</script>
<style lang="less" scoped>
/deep/.b4772FF {
    background-color: #edf1ff !important;
}
/deep/.el-dialog__title {
    font-size: 16px;
}
/deep/.el-dialog__body {
    padding: 10px 20px;
    border-top: 1px #e4e7ed solid;
    border-bottom: 1px #e4e7ed solid;
}
</style>
