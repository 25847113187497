<template>
    <div class="">
        <el-dialog :close-on-click-modal="false" append-to-body :title="title" :visible.sync="dialogVisible" width="1000px" :before-close="handleClose">
            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="90px" class="demo-ruleForm" status-icon>
                <el-form-item label="报表名称" prop="title">
                    <div class="flex-row-start">
                        <el-input v-model="ruleForm.title" style="width:300px" placeholder="请输入报表名称" />
                        <div class="ml10">(3~12个字)</div>
                    </div>
                </el-form-item>
                <el-form-item label="自定义SQL" prop="description">
                    <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 5}" v-model="ruleForm.description" style="width:90%" placeholder="#标题例：ID|店铺ID|店铺编码|名称|创建时间 #日期筛选字段：inTime SELECT label_id, store_id, shop_code, name, inTime FROM `my_label` WHERE 1 = 1"></el-input>
                </el-form-item>
                <el-form-item label="选择门店" prop="title">
                    <div class="flex-row-start">
                        <el-button @click="openStore" icon="el-icon-plus">选择</el-button>
                        <div class="flex-1">
                            <el-tag @close="onTag(index)" class="ml10" v-for="(item,index) in ruleForm.store_list" :key="index" closable>
                                {{item.shop_number}}-{{item.abbreviation}}
                            </el-tag>
                        </div>
                    </div>

                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit('ruleForm')">确 认</el-button>
            </span>
        </el-dialog>
        <storeTree ref="storeTree" @change="onStore"></storeTree>
        <storeList ref="storeList" @change="onStore"></storeList>
    </div>
</template>
<script>
import storeTree from '@/pages/setting/components/forms/store-tree.vue'
import storeList from '@/pages/setting/components/forms/store-list.vue'
export default {
    components: {
        storeTree, storeList
    },
    data() {
        return {
            id: null,
            title: '添加自定义SQL报表',
            dialogVisible: false,
            ruleForm: {
                title: '', description: '', store_list: [],
            },
            rules: {
                title: [
                    { required: true, message: '请输入报表名称', trigger: 'blur' }
                ],
                description: [
                    { required: true, message: '请输入自定义SQL', trigger: 'blur' }
                ],
                store_list: [
                    { required: true, message: '选择盘点人员', trigger: 'blur' }
                ],
            }
        }
    },
    mounted() {


    },
    methods: {
        open(rowobj) {
            let row = JSON.parse(JSON.stringify(rowobj))
            if (row.id) {
                this.id = row.id
                this.title = '编辑自定义SQL报表'
                this.ruleForm = {
                    title: row.title, description: row.description, store_list: row.shoplist,
                }
            } else {
                this.id = null
                this.title = '新增自定义SQL报表'
                this.ruleForm = {
                    title: '', description: '', store_list: [],
                }
            }
            this.dialogVisible = true
            this.$nextTick(() => {
                this.$refs['ruleForm'].clearValidate()
            })
        },
        openStore() {
            this.$refs.storeList.open()
        },
        onStore(list) {
            let arr = this.getArrDifference(list, this.ruleForm.store_list, 'shop_id')
            if (arr.length == 0) {
                this.$message.error('不可重复添加');
            } else {
                arr.forEach(item => {
                    this.ruleForm.store_list.push(item)
                });
            }
        },
        getArrDifference(array1, array2, type) {
            let result = [];
            for (let i = 0; i < array1.length; i++) {
                let isExist = false;
                for (let j = 0; j < array2.length; j++) {
                    if (array1[i][type] === array2[j][type]) {
                        isExist = true;
                        break;
                    }
                }
                if (!isExist) {
                    result.push(array1[i]);
                }
            }
            return result
        },
        onTag(index) {
            this.ruleForm.store_list.splice(index, 1)
        },
        submit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    const {
                        title, description
                    } = this.ruleForm
                    const datas = {
                        title, description
                    }
                    if (this.ruleForm.store_list.length <= 0) {
                        this.$message.error('请选择门店');
                        return
                    }
                    datas.store_id = ''
                    this.ruleForm.store_list.forEach(item => {
                        datas.store_id = datas.store_id + item.shop_id + ','
                    })
                    datas.store_id = datas.store_id.substring(0, datas.store_id.length - 1)

                    let url = '/api/DynamicReport/ReportAdd'
                    if (this.id) {
                        url = 'api/DynamicReport/ReportEdit'
                        datas.id = this.id
                    }

                    this.$http.api(url, datas).then(res => {
                        if (res.code == 1) {
                            this.dialogVisible = false
                            this.$emit('change', '')
                        } else if (res.code == 0 && res.msg) {
                            this.$message.error(res.msg);
                        }
                    }).catch(res => { })
                }
            })
        },
        handleClose() {
            this.dialogVisible = false
        },
    },

}
</script>

<style lang="less" scoped></style>
