<template>
    <div class="forms">
        <div class="form-search-box flex-row-start flex-wrap mt10">
            <div class="flex-row-start" style="margin-right:20px">
                <div style="font-size: 14px;color: #606266;font-weight: 700;margin-right:10px">报表名:</div>
                <el-input size="mini" style="width:200px" v-model="search.title" placeholder="输入报表名" />
            </div>
            <div class="flex-row-start" style="margin-right:20px">
                <div style="font-size: 14px;color: #606266;font-weight: 700;margin-right:10px">门店名称:</div>
                <el-input size="mini" style="width:200px" v-model="search.shopinfo" placeholder="门店连锁代码/编号/名称" />
            </div>
            <div class="flex-row-start" style="margin-right:20px">
                <div style="font-size: 14px;color: #606266;font-weight: 700;margin-right:10px">创建时间:</div>
                <el-date-picker style="width:260px" clearable v-model="time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" />
            </div>
            <div class="flex-row-start">
                <a-button @click="getLists(1)" type="primary">查 询</a-button>
                <a-button @click="onReset" class="ml10">重 置</a-button>
                <a-button @click="openEdit({})" class="ml10" type="primary" icon="plus">添 加</a-button>
            </div>
        </div>
        <div class="table-container mt20">
            <div class="wxb-table-white">
                <el-table v-if="!loading" v-loading="loading" ref="multipleTable" :data="tableData" fit highlight-current-row>
                    <el-table-column align="center" label="ID" width="80">
                        <template slot-scope="scope">
                            {{ scope.row.id? scope.row.id:'-'}}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="报表名称" minWidth="100">
                        <template slot-scope="scope">
                            {{ scope.row.title? scope.row.title:'-'}}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="适用门店" minWidth="140">
                        <template slot-scope="scope">
                            <span v-for="(item,index) in scope.row.shoplist" :key="index">
                                {{item.shop_code}},{{item.shop_number}},{{item.abbreviation}};
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="状态" minWidth="80">
                        <template slot-scope="scope">
                            {{ scope.row.Is_valid == 0? '有效':'无效'}}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="创建时间" min-width="100">
                        <template slot="header">
                            <div class="flex-row-center">
                                <div>创建时间</div>
                                <div class="table_tpb">
                                    <i @click="onVar('ASC','createtime')" class="el-icon-caret-top cursor-in font16" :class="search.orderway == 'ASC'&&search.field=='createtime'?'font-1584C8':'font-999999'"></i>
                                    <i @click="onVar('DESC','createtime')" class="el-icon-caret-bottom cursor-in font16" :class="search.orderway == 'DESC'&&search.field=='createtime'?'font-1584C8':'font-999999'"></i>
                                </div>
                            </div>
                        </template>
                        <template #default="scope">
                            {{scope.row.createtime?scope.row.createtime:'-'}}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="更新时间" min-width="100">
                        <template slot="header">
                            <div class="flex-row-center">
                                <div>更新时间</div>
                                <div class="table_tpb">
                                    <i @click="onVar('ASC','updatetime')" class="el-icon-caret-top cursor-in font16" :class="search.orderway == 'ASC'&&search.field=='updatetime'?'font-1584C8':'font-999999'"></i>
                                    <i @click="onVar('DESC','updatetime')" class="el-icon-caret-bottom cursor-in font16" :class="search.orderway == 'DESC'&&search.field=='updatetime'?'font-1584C8':'font-999999'"></i>
                                </div>
                            </div>
                        </template>
                        <template #default="scope">
                            {{scope.row.updatetime?scope.row.updatetime:'-'}}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作" minWidth="90">
                        <template slot-scope="scope">
                            <el-button style="color:#67C23A" @click="saveSigna(0,scope.row.id)" v-if="scope.row.Is_valid == 1" type="text">启用</el-button>
                            <el-button style="color:#F56C6C" @click="saveSigna(1,scope.row.id)" v-if="scope.row.Is_valid == 0" type="text">禁用</el-button>
                            <el-button type="text" @click="openEdit(scope.row)">编辑</el-button>
                            <el-button @click="onDel(scope.row.id)" style="color:#F56C6C" type="text">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <forms-edit ref="formsEdit" @change="getLists"></forms-edit>
    </div>
</template>

<script>
import formsEdit from '@/pages/setting/components/forms/edit.vue'
export default {
    components: {
        formsEdit
    },
    data() {
        return {
            time: [],
            tableData: [],
            dialogVisible: false,
            loading: false,
            total: 0,
            search: {
                title: '', shopinfo: '', field: '', orderway: '',
                date_start: '', date_end: '', id: '',
            },
        }
    },
    created() {
        this.getLists()
    },
    methods: {
        getLists() {
            if (this.loading == true) return;
            this.loading = true;
            this.$http
                .api("api/DynamicReport/ReportList", this.search)
                .then((res) => {
                    this.tableData = res.data;
                    this.loading = false;
                })
                .catch((res) => {
                    this.loading = false;
                });
        },
        onReset() {
            this.search = {
                title: '', shopinfo: '', date_start: '', date_end: '', field: '', orderway: '',
            }
            this.time = []
            this.getLists(1)
        },
        openEdit(row) {
            this.$refs.formsEdit.open(row)
        },

        onAdd(row) {
            if (row.id) {
                this.ruleForm = {
                    name: row.name, category: row.category + '', file_url: row.file_url, remarks: row.remarks, shop_code: row.shop_code,
                }
                this.id = row.id
            } else {
                this.ruleForm = {
                    name: '', category: '1', file_url: '', remarks: '', shop_code: '',
                }
            }
            this.dialogVisible = true
        },

        saveSigna(status, id) {
            this.$http.api('api/DynamicReport/ReportValid', { id: id, Is_valid: status }).then(res => {
                this.getLists(1)
            }).catch(res => {
            })
        },
        onVar(res, type) {
            this.search.orderway = res
            this.search.field = type
            this.getLists(1)
        },
        //删除
        onDel(id) {
            this.$alert('确认操作?', '提示', {
                confirmButtonText: '确定',
                callback: action => {
                    this.$http.api('api/DynamicReport/ReportDel', { id: id }).then(res => {
                        this.getLists(1)
                    }).catch(res => {
                    })
                }
            });
        },
    }
}
</script>

<style lang="less" scoped>
/deep/ .el-range-separator {
    width: auto !important;
}
.forms {
    .image_bos {
        .image_list {
            height: 70px;
            width: 70px;
            border-radius: 5px;
            overflow: hidden;
            display: inline-block;
            margin-left: 10px;
            position: relative;
            .img {
                height: 70px;
                width: 70px;
            }
            .demo_cover {
                position: absolute;
                top: 0;
                left: 0;
                height: 0px;
                width: 0px;
                background: rgba(0, 0, 0, 0.6);
                overflow: hidden;
                cursor: pointer;
            }
        }
        .image_list:hover {
            .demo_cover {
                height: 70px;
                width: 70px;
            }
        }
    }
}

.file_url {
    width: 100px;
    height: 60px;
    border-radius: 4px;
    cursor: pointer;
}
.cursor-in {
    cursor: pointer;
}
//表头里面的上下选择
.table_tpb {
    margin-left: 5px;

    i {
        display: block;
        cursor: pointer;
    }

    .el-icon-caret-top {
        margin-bottom: -5px;
    }

    .el-icon-caret-bottom {
        margin-top: -5px;
    }
}

.font-1584C8 {
    color: #1584c8;
}

.font-999999 {
    color: #999999;
}
</style>